<template>
	<div class="doc-nav">
		<v-tree v-if="nav.length">
			<v-tree-node v-for="(item, i) in nav" :key="i" :item="item" />
		</v-tree>
	</div>
</template>

<script lang="ts" setup>
	const { docsNav: nav } = storeToRefs(useGlobalStore())
</script>

<style lang="scss"></style>
